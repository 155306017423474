import React from 'react';
import ReactDOM from 'react-dom/client';
import './Greeting.css';


function Greeting(props){

    return(
        <div className='Greeting'>

            <div id="GreetingLogo">
                <img width={"100%"} src={process.env.PUBLIC_URL +"/logo.svg"} />
            </div>

            <div id="GreetingSlogan">
                <p style={{ color: 'white',fontFamily: 'sans-serif' ,fontWeight: 'bold',fontSize: '0.5em',textAlign: "left"}}>JUNBEOM IN</p>
                <p style={{ color: 'white',fontFamily: 'serif' ,fontSize: '0.2em',textAlign: "left"}}>Cybersecurity Analytics and Operations, B.S.</p>
                <p style={{ color: 'white',fontFamily: 'serif' ,fontSize: '0.2em',textAlign: "left"}}>Penn State class of 2024</p>
            </div>
            
            <video autoPlay muted poster={process.env.PUBLIC_URL +"/poster.png"} preload="auto" id="greetingVideo" >
                <source src={process.env.PUBLIC_URL +"/greeting.mp4"} type="video/mp4" />
            </video>



        </div>
    );
}

export default Greeting;