import React from "react";
import './Contact.css';

function Contact(props){
    const lines = ["言いたいことがあるんだよ","やっぱりJUNはかわいいよ","好き好き大好きやっぱ好き！","やっと見つけたお王子様","俺が生まれてきた理由","それはお前に出会うため","俺と一緒に人生歩もう","世界で一番愛してる！"];




    return(
        <div className="Contact">
            <div className="BackgroundText">
                {lines.map(line => (
                        <div className="BackgoundText_Individual">
                            <span>
                            {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line} {line}
                            </span>
                        </div>
                
                ))}
            </div>
            <div className="contactBox">
                  <h1>CONTACT</h1>
                  <p>positively@iamyourjun.com</p>
            </div>
        </div>
    );
}

export default Contact;