import './App.css';


import Greeting from './components/Greeting';
import Contact from './components/Contact';

const publicFileURL = "../public/"

function App() {
  
  return (
    <div className="App Parent">

        <Greeting className="Child" publicURL={publicFileURL} />
        <Contact className="Child" publicURL={publicFileURL} />

    </div>
  );
}

export default App;
